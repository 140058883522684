"use client";
//? Library Imports ------------------------------------------------------------>
import { useEffect } from "react";
import { Row, Col, Button, Result, Typography } from "antd";
import "./error.scss";
//? Library Constants ---------------------------------------------------------->
const { Text } = Typography;

export default function Error({ error, reset }) {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error);
  }, [error]);

  return (
    <div>
      {/* <h2>Something went wrong!</h2>
      <button
        onClick={
          // Attempt to recover by trying to re-render the segment
          () => reset()
        }
      >
        Try again
      </button> */}

      {/* <div id="notfound">
        <div className="notfound">
          <div className="notfound-404"></div>
          <h1>404</h1>
          <h2>Something went wrong!</h2>
          <p>
            Sorry but the page you are looking for does not exist, have been
            removed. name changed or is temporarily unavailable
          </p>

          <button
            className="primary_btn_outline borderRadius5"
            style={{
              height: "40px",
              fontSize: "16px",
              borderRadius: "3px",
              textTransform: "capitalize",
            }}
            onClick={
              // Attempt to recover by trying to re-render the segment
              () => reset()
            }
          >
            Try again!
          </button>
        </div>
      </div> */}

      <Row justify="center">
        <Col span={6} xs={24}>
          <Result
            status="500"
            // title="500"
            className="flexColumn alignCenter"
            subTitle={<Text>Sorry, something went wrong.</Text>}
            extra={
              <Button
                // type="primary"
                onClick={() => reset()}
                className="primary_btn borderRadius5"
              >
                Try Again!
              </Button>
            }
          />
        </Col>
      </Row>
    </div>
  );
}
